'use client'
import { ProductSlider } from '@components/category'
import { ContentfulNextJsImage } from '@components/common/ContentfulImage'
import { NestedVerticalFeature } from '../ConfigurableFeatures/NestedVerticalFeature/NestedVerticalFeature'
import { MobileConfigurableList } from '../ConfigurableList/MobileConfigurableList'
import { Button } from '@noissue-ui-kit/button'
import { PostOrPage } from '@tryghost/content-api'
import { IImage } from '@utils/contentful/contentfulImage'
import { BaseTemplate } from 'types/templates'
import { IConfigurableFeature } from '../ConfigurableFeatures/features.types'
import {
  TConfigurableColor,
  TConfigurableTitleSize,
} from '../contentfulPage.types'
import {
  buttonTypeToVariant,
  ctaColors,
  titleSizeToClass,
} from '../contentfulPage.utils'
import { ConfigurableListInfinitySlider } from './ConfigurableListInfinitySlider'
import { useInView } from 'react-intersection-observer'
import { TRecommendedProduct } from 'types/crossSell'
import { ContentfulMarkdown } from '@utils/contentful/ContentfulMarkdown'
import { twMerge } from 'tailwind-merge'
import { TCtaButtonColor } from 'types/contentful/featuresList'
import { TheWrap } from '@components/category/TheWrap/TheWrap'
import { CategoryCollection, TCategory } from './CategoryCollection'
import { trackConfigurableListImpression, trackCTAClick } from '@utils/gtm'
import { usePathname } from 'next/navigation'
import EverestVisibilityDetector from '@components/common/EverestVisibilityDetector'
import { ComponentPlaceholder } from '../ComponentPlaceholder'
import { ConfigurableListMarqueue } from './ConfigurableListMarqueue'
import { SingleConfigurableCard } from '../ConfigurableCard'
import { IContentfulConfigurableCard } from 'everest/component/StickySideSlider'
import { mapTextColorToClass } from 'app/_components/ContentfulPage/ConfigurableText/ConfigurableText.utils'
import { FeaturesGrid } from './FeaturesGrid'
import { NestedVerticalFeatureV2 } from '../ConfigurableFeatures/NestedVerticalFeature/NestedVerticalFeatureV2'
import { MultiTabFeaturesList } from './MultiTabFeaturesList'
import { ConfigurableText } from '../ConfigurableText/ConfigurableText'
import isEmpty from 'lodash/isEmpty'
import { IConfigurableText } from '../ConfigurableText/ConfigurableText.types'

export type TConfigurableListVariant =
  | 'you may also like section'
  | 'infinite scroll marquee'
  | 'multi tab features list'
  | 'features collection'
  | 'category collection'
  | 'the wrap posts'
  | 'features grid'
  | 'tile list'

export const LIST_VARIANTS: Record<string, TConfigurableListVariant> = {
  TILE_LIST: 'tile list',
  THE_WRAP_POSTS: 'the wrap posts',
  FEATURES_GRID: 'features grid',
  CATEGORY_COLLECTION: 'category collection',
  FEATURES_COLLECTION: 'features collection',
  PRODUCTS_SUGGESTIONS: 'you may also like section',
  MULTI_TAB_FEATURES_LIST: 'multi tab features list',
  INFINITE_SCROLL_MARQUEUE: 'infinite scroll marquee',
}

export interface IMinimalisticFeature {
  title: string
  description: string
  image: IImage
}
export interface IConfigurableList {
  id: string
  componentKey: 'featuresList'
  submenuLabel: string
  backgroundColor: string
  branding: 'old' | 'new'
  title: string
  markdownTitle?: string
  titleConfig?: IConfigurableText
  titleSize?: TConfigurableTitleSize
  titleColor?: TConfigurableColor
  textAlign?: 'left' | 'center' | 'right'
  subtitle?: string
  subtitleColor?: string
  description?: string
  ctaLabel?: string
  ctaLink?: string
  ctaButtonType?: string
  ctaButtonColor?: TCtaButtonColor
  ctaEventId: string
  topImage?: IImage
  features?:
    | IConfigurableFeature[]
    | IContentfulConfigurableCard[]
    | IMinimalisticFeature[]
  productTemplates?: BaseTemplate[]
  wrapBlogPosts?: Partial<PostOrPage>[]
  suggestedProducts: TRecommendedProduct[]
  categoryCollection: TCategory[]
  listVariant: TConfigurableListVariant
  onCtaAction?: ({ ctaId }) => void
  trackingIdentifier: string
  generateStatically?: boolean
  nestedLists?: IConfigurableList[]
}

const templatesToSliderItems = (templates) => {
  if (templates && templates.length) {
    return templates.map((x) => {
      return {
        title: x.title,
        imageUrl: x.images[0],
      }
    })
  }
  return []
}

export default function ConfigurableList({
  title,
  markdownTitle,
  backgroundColor,
  branding = 'old',
  titleConfig,
  titleSize = 'L',
  titleColor = 'acai',
  textAlign = 'center',
  subtitle,
  subtitleColor = 'grey',
  description,
  ctaLabel,
  ctaLink,
  ctaButtonType,
  ctaButtonColor,
  ctaEventId,
  topImage,
  features,
  productTemplates,
  suggestedProducts,
  categoryCollection,
  wrapBlogPosts,
  listVariant,
  onCtaAction,
  trackingIdentifier,
  generateStatically = false,
  nestedLists,
}: IConfigurableList) {
  const pathname = usePathname()
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '100px 0px',
  })
  const dynamicStyles = {
    backgroundColor: backgroundColor,
  }

  const onCtaClick = () => {
    trackCTAClick({
      ctaLinkUrl: ctaLink,
      componentIdentifier: `${trackingIdentifier}-cta`,
      pageUrl: pathname,
      ctaLabel: ctaLabel,
    })
    if (ctaLink) {
      return
    }
    if (onCtaAction) {
      onCtaAction({ ctaId: ctaEventId })
    }
  }

  const templatesAsSliderItems = templatesToSliderItems(productTemplates)
  const COLLECTION_PLACEHOLDER_HEIGHT = 480
  const THE_WRAP_PLACEHOLDER_HEIGHT = 730

  const textAlignToClass = {
    left: 'text-left justify-start',
    center: 'text-center justify-center',
    right: 'text-right justify-end',
  }

  // Category Collection is quite a different style to other ConfigurableLists. This is to isolate it here
  if (listVariant === LIST_VARIANTS.CATEGORY_COLLECTION) {
    return (
      <div
        className={`font-mori relative flex flex-col items-center justify-center w-full sm:py-[32px] overflow-x-hidden bg-core-purple-10`}
        style={dynamicStyles}
        ref={ref}
      >
        {generateStatically || inView ? (
          <EverestVisibilityDetector
            onVisible={() => {
              trackConfigurableListImpression({
                componentIdentifier: trackingIdentifier,
                title: title,
                pageUrl: pathname,
              })
            }}
            once={true}
          >
            <>
              {(title || ctaLabel) && (
                <div className="flex px-8 md:px-[60px] lg:px-[120px] w-full items-center justify-between mb-[16px]">
                  {markdownTitle && (
                    <ContentfulMarkdown
                      overrideClass={`
                        ${titleSizeToClass[titleSize] || titleSizeToClass.L}
                        ${
                          mapTextColorToClass[titleColor] ||
                          mapTextColorToClass.acai
                        }
                        ${ctaLabel ? 'w-full md:w-3/5' : 'w-full'}`}
                    >
                      {markdownTitle || title}
                    </ContentfulMarkdown>
                  )}

                  {ctaLabel && (
                    <div className="justify-end hidden grow md:flex">
                      <Button
                        Component={ctaLink ? 'a' : 'button'}
                        href={ctaLink}
                        variant={buttonTypeToVariant[ctaButtonType]}
                        size="m"
                        corners="rounded"
                        className="w-full max-w-fit"
                        colour={ctaColors[ctaButtonColor] || ctaColors.pitaya}
                        onClick={onCtaClick}
                      >
                        {ctaLabel}
                      </Button>
                    </div>
                  )}
                </div>
              )}

              <CategoryCollection
                categoryCollection={categoryCollection}
                trackingIdentifier={trackingIdentifier}
              />

              {ctaLabel && (
                <div className="w-full px-8 md:hidden">
                  <Button
                    Component={ctaLink ? 'a' : 'button'}
                    href={ctaLink}
                    variant={buttonTypeToVariant[ctaButtonType]}
                    size="m"
                    corners="rounded"
                    className="w-full"
                    colour={ctaColors[ctaButtonColor] || ctaColors.pitaya}
                    onClick={onCtaClick}
                  >
                    {ctaLabel}
                  </Button>
                </div>
              )}
            </>
          </EverestVisibilityDetector>
        ) : (
          <ComponentPlaceholder height={COLLECTION_PLACEHOLDER_HEIGHT} />
        )}
      </div>
    )
  }

  return (
    <div
      className={`font-mori tracking-mori relative flex flex-col items-center justify-center w-full overflow-x-hidden bg-core-purple-10
        ${
          listVariant === LIST_VARIANTS.THE_WRAP_POSTS ? 'py-32' : 'pt-[16px]'
        }`}
      style={dynamicStyles}
      ref={ref}
    >
      {generateStatically || inView ? (
        <EverestVisibilityDetector
          onVisible={() => {
            trackConfigurableListImpression({
              componentIdentifier: trackingIdentifier,
              title: title,
              pageUrl: pathname,
            })
          }}
          once={true}
        >
          <>
            <div className="flex flex-col px-6 sm:px-[120px] w-full">
              {topImage?.imageUrl && (
                <div className="flex justify-center">
                  <ContentfulNextJsImage
                    width={80}
                    height={80}
                    src={topImage?.imageUrl}
                    alt={topImage?.imageTitle}
                    objectFit="fill"
                    quality={60} // No format due to png has transparent background
                  />
                </div>
              )}

              {subtitle && (
                <h4
                  className={twMerge(
                    `text-[12px] leading-tight mb-4 mt-4 ${
                      textAlignToClass[textAlign]
                    } ${
                      mapTextColorToClass[subtitleColor] ||
                      mapTextColorToClass.acai
                    }`
                  )}
                >
                  {subtitle}
                </h4>
              )}

              {(markdownTitle || title) && (
                <>
                  {!isEmpty(titleConfig) && (
                    <ConfigurableText
                      {...titleConfig}
                      className={`
                      ${
                        description || ctaLabel
                          ? 'mb-6 sm:mb-6'
                          : 'mb-10 sm:mb-12'
                      }
                      ${topImage?.imageUrl && !subtitle ? 'mt-4 sm:mt-6' : ''}
                    `}
                    >
                      {markdownTitle || title}
                    </ConfigurableText>
                  )}

                  {isEmpty(titleConfig) && (
                    <ContentfulMarkdown
                      overrideClass={`font-mori font-bold flex md:flex-row flex-col w-full md:leading-tight leading-snug
                      ${textAlignToClass[textAlign]}
                      ${
                        description || ctaLabel
                          ? 'mb-6 sm:mb-6'
                          : 'mb-10 sm:mb-12'
                      }
                      ${topImage?.imageUrl && !subtitle ? 'mt-4 sm:mt-6' : ''}
                      ${titleSizeToClass[titleSize] || titleSizeToClass.L}
                      ${
                        mapTextColorToClass[titleColor] ||
                        mapTextColorToClass.acai
                      }`}
                    >
                      {markdownTitle || title}
                    </ContentfulMarkdown>
                  )}
                </>
              )}

              {description && (
                <ContentfulMarkdown overrideClass="text-boulder text-2xl sm:text-4xl px-8 mb-16 leading-snug text-center">
                  {description}
                </ContentfulMarkdown>
              )}
            </div>

            {/* Right now The wrap's cta button is below the wrap, 
              have no idea about other components,
              thus keep the cta button on the top in new branding except the wrap component
          */}
            {ctaLabel && listVariant !== LIST_VARIANTS.THE_WRAP_POSTS && (
              <div
                className={`flex flex-col mx-auto px-[20px] md:px-0 w-full ${
                  description ? 'mt-0' : 'mt-10'
                }`}
              >
                <Button
                  Component={ctaLink ? 'a' : 'button'}
                  href={ctaLink}
                  variant={buttonTypeToVariant[ctaButtonType]}
                  size="m"
                  corners="rounded"
                  className="w-full mx-auto max-w-fit"
                  colour={ctaColors[ctaButtonColor] || ctaColors.pitaya}
                  onClick={onCtaClick}
                >
                  {ctaLabel}
                </Button>
              </div>
            )}

            {listVariant === LIST_VARIANTS.FEATURES_COLLECTION &&
            features?.length ? (
              <>
                {branding === 'old' && (
                  <>
                    <div className="hidden sm:flex xl:w-[1170px] w-full justify-center mx-auto max-w-full px-8 flex-wrap gap-8">
                      {features.map((item) => (
                        <NestedVerticalFeature
                          {...item}
                          key={item.id}
                          onCtaAction={onCtaAction}
                        />
                      ))}
                    </div>

                    <MobileConfigurableList
                      sliderItems={features}
                      SliderItemComponent={NestedVerticalFeature}
                      keyProp="id"
                    />
                  </>
                )}

                {branding === 'new' && (
                  <>
                    <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-16 sm:gap-24 mx-auto px-6 sm:px-[120px]">
                      {features.map((item) => (
                        <NestedVerticalFeatureV2
                          {...item}
                          key={item.id}
                          onCtaAction={onCtaAction}
                        />
                      ))}
                    </div>
                  </>
                )}
              </>
            ) : null}

            {listVariant === LIST_VARIANTS.TILE_LIST && features?.length ? (
              <div className="flex flex-col md:flex-row md:flex-wrap lg:flex-nowrap relative justify-start w-full h-auto lg:h-[380px]">
                {features.map((featureCard) => (
                  <div
                    className={`mx-4 mb-4 md:mx-[2px] md:odd:ml-0 md:even:mr-0 md:mb-[4px] lg:odd:ml-[2px] lg:even:mr-[2px] lg:first:!ml-0 lg:last:!mr-0 md:w-[calc(50%-4px)] lg:w-1/4`}
                  >
                    <SingleConfigurableCard
                      {...featureCard}
                      key={featureCard.id}
                    />
                  </div>
                ))}
              </div>
            ) : null}

            {listVariant === LIST_VARIANTS.TEMPLATES_SLIDER &&
              templatesAsSliderItems.length && (
                <ConfigurableListInfinitySlider
                  items={templatesAsSliderItems}
                />
              )}

            {listVariant === LIST_VARIANTS.PRODUCTS_SUGGESTIONS &&
            suggestedProducts?.length ? (
              <ProductSlider data={suggestedProducts} />
            ) : null}

            {listVariant === LIST_VARIANTS.FEATURES_GRID && features?.length ? (
              <FeaturesGrid features={features as IMinimalisticFeature[]} />
            ) : null}

            {listVariant === LIST_VARIANTS.THE_WRAP_POSTS &&
              Boolean(wrapBlogPosts?.length) && (
                <>
                  <TheWrap
                    posts={wrapBlogPosts}
                    trackingIdentifier={trackingIdentifier}
                  />

                  {ctaLabel && (
                    <div
                      className={`flex flex-col mx-auto px-[20px] md:px-0 w-full mt-[56px]`}
                    >
                      <Button
                        Component={ctaLink ? 'a' : 'button'}
                        href={ctaLink}
                        variant={buttonTypeToVariant[ctaButtonType]}
                        size="m"
                        corners="rounded"
                        className="w-full mx-auto max-w-fit"
                        colour={ctaColors[ctaButtonColor] || ctaColors.pitaya}
                        onClick={onCtaClick}
                      >
                        {ctaLabel}
                      </Button>
                    </div>
                  )}
                </>
              )}

            {listVariant === LIST_VARIANTS.INFINITE_SCROLL_MARQUEUE && (
              <ConfigurableListMarqueue
                features={features as IConfigurableFeature[]}
              />
            )}

            {listVariant === LIST_VARIANTS.MULTI_TAB_FEATURES_LIST && (
              <MultiTabFeaturesList nestedLists={nestedLists} />
            )}
          </>
        </EverestVisibilityDetector>
      ) : (
        <ComponentPlaceholder
          height={
            listVariant === LIST_VARIANTS.THE_WRAP_POSTS
              ? THE_WRAP_PLACEHOLDER_HEIGHT
              : 500
          }
        />
      )}
    </div>
  )
}
