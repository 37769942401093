import { ContentfulNextJsImage } from '@components/common/ContentfulImage'
import { IConfigurableFeature } from '../features.types'
import { titleSizeToClass } from 'app/_components/ContentfulPage/contentfulPage.utils'
import { ContentfulMarkdown } from '@utils/contentful/ContentfulMarkdown'
import { TConfigurableFeatureImageSize } from 'app/_components/ContentfulPage/contentfulPage.types'
import { useDevice } from '@utils/responsiveness/useDevice'
import { ConfigurableCta } from 'app/_components/ContentfulPage/ConfigurableCta'
import { mapTextColorToClass } from 'app/_components/ContentfulPage/ConfigurableText/ConfigurableText.utils'

export function NestedVerticalFeature({
  title,
  titleColor,
  titleSize,
  subtitle,
  subtitleColor,
  description,
  ctaLabel,
  ctaColor,
  ctaType,
  ctaBtnVariant,
  ctaLink,
  ctaEventId,
  image,
  imageSize = 'M',
  className,
  onCtaAction,
}: IConfigurableFeature) {
  const { isMobile } = useDevice()
  const mapImageSizeToPx: Omit<
    { [key in TConfigurableFeatureImageSize]: number },
    'Full'
  > = {
    XS: isMobile ? 64 : 32,
    S: isMobile ? 96 : 48,
    M: isMobile ? 160 : 80,
    L: isMobile ? 240 : 120,
    XL: isMobile ? 320 : 180,
  }

  const onCtaClick = () => {
    if (onCtaAction) {
      onCtaAction({ ctaId: ctaEventId })
    }
  }

  return (
    <div
      className={`mb-8 sm:mb-0 flex items-center mx-4 flex-col ${className}`}
    >
      {image.imageUrl && (
        <ContentfulNextJsImage
          width={mapImageSizeToPx[imageSize] || mapImageSizeToPx.M}
          height={mapImageSizeToPx[imageSize] || mapImageSizeToPx.M}
          src={image.imageUrl}
          alt={image?.imageTitle}
          objectFit="contain"
          quality={60} // No format due to png has transparent background
        />
      )}

      {subtitle && (
        <h5
          className={`text-[12px] leading-tight font-normal text-center text-core-grey-80 mb-2 mt-5 ${
            mapTextColorToClass[subtitleColor] || mapTextColorToClass.grey
          }`}
        >
          {subtitle}
        </h5>
      )}
      {title && (
        <h3
          className={`max-w-[270px] mx-auto ${
            subtitle ? '' : 'mt-5'
          } mb-3 text-3xl font-bold leading-tight text-core-purple text-center ${
            titleSizeToClass[titleSize] || titleSizeToClass.S
          } ${mapTextColorToClass[titleColor] || mapTextColorToClass.purple}`}
        >
          {title}
        </h3>
      )}

      {description && (
        <div className="mb-[6px] max-w-[270px]">
          <ContentfulMarkdown overrideClass="text-center text-[12px] lg:text-[14px] leading-normal text-core-grey-90">
            {description}
          </ContentfulMarkdown>
        </div>
      )}

      {ctaLabel && (
        <ConfigurableCta
          ctaLabel={ctaLabel}
          ctaType={ctaType}
          ctaBtnVariant={ctaBtnVariant}
          ctaColor={ctaColor}
          ctaLink={ctaLink}
          className="mx-auto mt-6"
          onClick={onCtaClick}
        />
      )}
    </div>
  )
}
