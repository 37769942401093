import EverestVisibilityDetector from '@components/common/EverestVisibilityDetector'
import { KeenSliderWrapper } from '@components/common/KeenSliderWrapper'
import { Button } from '@noissue-ui-kit/button'
import { ProductTile } from '@pageFeatures/category/ProductTile'
import { TProductTileData } from '@pageFeatures/category/ProductTile/ProductTile.types'
import {
  trackProductClick,
  trackProductImpressionForCategoryCollectionComponent,
} from '@utils/gtm'
import { useDevice } from '@utils/responsiveness/useDevice'
import { usePathname } from 'next/navigation'
import { useState } from 'react'

export type TCategory = {
  categoryName: string
  productsInCategory: TProductTileData[]
}

export function CategoryCollection({
  categoryCollection,
  trackingIdentifier,
}: {
  categoryCollection: TCategory[]
  trackingIdentifier: string
}) {
  const { isMobile } = useDevice()
  const pathname = usePathname()
  const sliderBreakpoints = {
    '(min-width: 480px)': {
      slides: { perView: 1.4, spacing: 16, origin: 'auto' as null },
    },
    '(min-width: 768px)': {
      slides: { perView: 2.2, spacing: 16, origin: 'auto' as null },
    },
    '(min-width: 1024px)': {
      slides: { perView: 3.2, spacing: 16, origin: 'auto' as null },
    },
    '(min-width: 1440px)': {
      slides: { perView: 3.6, spacing: 16, origin: 'auto' as null },
    },
  }

  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0)

  return (
    <>
      <div className="w-full px-4 md:px-[60px] lg:px-[120px] mb-12 relative">
        {categoryCollection?.length > 1 && (
          <div className="flex w-[100vw] sm:w-full -translate-x-4 sm:translate-x-0 gap-[8px] mb-[32px] md:mb-[64px] overflow-x-auto no-scrollbar">
            {categoryCollection?.map((category, index) => {
              return (
                <Button
                  onClick={() => {
                    setSelectedCategoryIndex(index)
                  }}
                  key={category?.categoryName}
                  className={`first:ml-8 sm:first:ml-0 cursor-pointer text-[18px] whitespace-nowrap ${
                    index === selectedCategoryIndex &&
                    'text-pitaya font-semibold'
                  }`}
                  variant="primary"
                  colour={`${
                    index === selectedCategoryIndex ? 'pitaya' : 'pale'
                  }`}
                  corners="rounded"
                  size={isMobile ? 's' : 'm'}
                >
                  {category?.categoryName}
                </Button>
              )
            })}
          </div>
        )}

        <KeenSliderWrapper
          initialConfig={{
            perView: 1.17,
            spacing: 16,
            origin: 'auto',
          }}
          breakpoints={sliderBreakpoints}
          isShowArrows={!isMobile}
          hideArrowsWhenDisabled={true}
          className="!hidden sm:!flex !overflow-visible overflow-y-hidden grow-0 h-[172px] sm:h-[600px] md:h-[500px] lg:h-[450px] xl:h-[500px] 2xl:h-[600px]"
          trackingIdentifier={trackingIdentifier}
          key={selectedCategoryIndex}
        >
          {categoryCollection[selectedCategoryIndex]?.productsInCategory?.map(
            (product, index) => {
              return (
                <div
                  className="keen-slider__slide !overflow-visible"
                  key={product.productName + '_' + index}
                >
                  <EverestVisibilityDetector
                    key={product.productName}
                    once
                    onVisible={() => {
                      trackProductImpressionForCategoryCollectionComponent({
                        productName: product?.productName,
                        slug: product?.urlSlug,
                        pageUrl: pathname,
                        componentIdentifier: trackingIdentifier,
                      })
                    }}
                  >
                    <ProductTile
                      linkUrl={product.linkUrl}
                      productName={product.productName}
                      imageTags={product.imageTags}
                      productTags={product.productTags}
                      pricePerUnit={product.pricePerUnit}
                      salePricePerUnit={product.salePricePerUnit}
                      primaryImage={product.primaryImage}
                      imageBackgroundColor={product.imageBackgroundColor}
                      productDetails={product.productDetails}
                      productType={product.productType}
                      onClick={() => {
                        trackProductClick({
                          list: `${trackingIdentifier}`,
                          buttonText: '',
                          item: {
                            name: product?.productName,
                            slug: product?.urlSlug,
                            pricePerUnit:
                              product?.pricePerUnit?.toString() || '',
                          },
                          position: index,
                        })
                      }}
                    />
                  </EverestVisibilityDetector>
                </div>
              )
            }
          )}
        </KeenSliderWrapper>

        <div className="flex flex-wrap sm:hidden">
          {categoryCollection[selectedCategoryIndex]?.productsInCategory?.map(
            (product, index) => {
              return (
                <EverestVisibilityDetector
                  key={product.productName}
                  once
                  onVisible={() => {
                    trackProductImpressionForCategoryCollectionComponent({
                      productName: product?.productName,
                      slug: product?.urlSlug,
                      pageUrl: pathname,
                      componentIdentifier: trackingIdentifier,
                    })
                  }}
                >
                  <ProductTile
                    linkUrl={product.linkUrl}
                    productName={product.productName}
                    imageTags={product.imageTags}
                    productTags={product.productTags}
                    pricePerUnit={product.pricePerUnit}
                    salePricePerUnit={product.salePricePerUnit}
                    primaryImage={product.primaryImage}
                    imageBackgroundColor={product.imageBackgroundColor}
                    productDetails={product.productDetails}
                    productType={product.productType}
                    compactLayout={true}
                    onClick={() => {
                      trackProductClick({
                        list: `${trackingIdentifier}`,
                        buttonText: '',
                        item: {
                          name: product?.productName,
                          slug: product?.urlSlug,
                          pricePerUnit: product?.pricePerUnit?.toString() || '',
                        },
                        position: index,
                      })
                    }}
                  />
                </EverestVisibilityDetector>
              )
            }
          )}
        </div>
      </div>
    </>
  )
}
